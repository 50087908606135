export function isTouchEnabled() {
    return ( 'ontouchstart' in window ) ||
           ( navigator.maxTouchPoints > 0 ) ||
           ( navigator.msMaxTouchPoints > 0 );
}

export function enableBodyScroll() {
    document.body.classList.remove('no-scroll');
};

export function disableBodyScroll() {
    document.body.classList.add('no-scroll');
};
