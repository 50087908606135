(() => {
    const $subMenuDropdownWraps = document.querySelectorAll('.site-menu-bar__menu .menu > .menu-item-has-children');
    const $subMenuWraps = document.querySelectorAll('.site-menu-bar__menu .menu-item-has-children .menu-item-has-children');

    const enableSubMenu = ($subMenuWrap, $subMenuToggle) => {
        if ($subMenuWrap.classList.contains('menu-item--active')) return;

        $subMenuWrap.classList.add('menu-item--active');
        $subMenuToggle.setAttribute('aria-expanded', 'true');
    };

    const disableSubMenu = ($subMenuWrap, $subMenuToggle) => {
        $subMenuWrap.classList.remove('menu-item--active');
        $subMenuToggle.setAttribute('aria-expanded', 'false');
    };

    const initSubMenuDropdowns = () => {
        $subMenuDropdownWraps.forEach($subMenuWrap => {
            if (!$subMenuWraps) return;

            const $subMenuToggle = $subMenuWrap.querySelector('a');

            if (!$subMenuToggle) return;

            $subMenuToggle.addEventListener('click', e => {
                e.preventDefault();
                
                if ($subMenuWrap.classList.contains('menu-item--active')) {
                    disableSubMenu($subMenuWrap, $subMenuToggle);
                } else {
                    enableSubMenu($subMenuWrap, $subMenuToggle);
                }
            });

            window.addEventListener('click', e => {
                if ($subMenuWrap.contains(e.target)) return;
    
                disableSubMenu($subMenuWrap, $subMenuToggle);
            });
        });
    };

    initSubMenuDropdowns();
})();
