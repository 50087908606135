(() => {
    const $resizeableIframes = document.querySelectorAll('.resizeable-iframe');

    if (!$resizeableIframes) return;

    $resizeableIframes.forEach($resizeableIframe => {
        const initIframeResizer = () => {
            iframeResize({
                license: 'GPLv3',
                onMessage(messageData) {
                    if (messageData?.message?.type === 'inlineIframeLink') {
                        if (!messageData?.message?.data?.url) return;

                        window.history.replaceState({}, '', messageData.message.data.url);

                        return;
                    }

                    if (messageData?.message?.type === 'showSaleOverview') {
                        const $singleSaleOverviews = document.querySelectorAll('.single-sale-overview');

                        if (!$singleSaleOverviews) return;

                        $singleSaleOverviews.forEach($singleSaleOverview => {
                            $singleSaleOverview.classList.remove('single-sale-overview--hidden');
                        });

                        return;
                    }

                    if (messageData?.message?.type === 'hideSaleOverview') {
                        const $singleSaleOverviews = document.querySelectorAll('.single-sale-overview');

                        if (!$singleSaleOverviews) return;

                        $singleSaleOverviews.forEach($singleSaleOverview => {
                            $singleSaleOverview.classList.add('single-sale-overview--hidden');
                        });

                        return;
                    }
                },
            }, $resizeableIframe);
        };

        initIframeResizer();
    });
})();
