(() => {
    const $subMenuDropdownWraps = document.querySelectorAll('.site-menu-modal__menu .menu-item-has-children');
    const $subMenuWraps = document.querySelectorAll('.site-menu-modal__menu .menu-item-has-children .menu-item-has-children');

    const enableSubMenu = ($subMenuWrap, $subMenuToggle) => {
        if ($subMenuWrap.classList.contains('menu-item--active')) return;

        const $subMenuDropdown = $subMenuWrap.querySelector('.sub-menu-dropdown');

        $subMenuDropdown.style.height = '0px';

        window.requestAnimationFrame(() => {
            $subMenuWrap.classList.add('menu-item--active');
    
            $subMenuToggle.setAttribute('aria-expanded', 'true');

            $subMenuDropdown.style.height = `${$subMenuDropdown.scrollHeight}px`;

            setTimeout(() => {
                $subMenuDropdown.style.height = '';
            }, 300);
        });
    };

    const disableSubMenu = ($subMenuWrap, $subMenuToggle) => {
        const $subMenuDropdown = $subMenuWrap.querySelector('.sub-menu-dropdown');

        $subMenuDropdown.style.height = `${$subMenuDropdown.scrollHeight}px`;

        window.requestAnimationFrame(() => {
            $subMenuWrap.classList.remove('menu-item--active');
    
            $subMenuToggle.setAttribute('aria-expanded', 'false');

            $subMenuDropdown.style.height = '0px';

            setTimeout(() => {
                $subMenuDropdown.style.height = '';
            }, 300);
        });
    };

    const initSubMenuDropdowns = () => {
        $subMenuDropdownWraps.forEach($subMenuWrap => {
            if (!$subMenuWraps) return;

            const $subMenuToggle = $subMenuWrap.querySelector('a');

            if (!$subMenuToggle) return;

            $subMenuToggle.addEventListener('click', e => {
                e.preventDefault();
                
                if ($subMenuWrap.classList.contains('menu-item--active')) {
                    disableSubMenu($subMenuWrap, $subMenuToggle);
                } else {
                    enableSubMenu($subMenuWrap, $subMenuToggle);
                }
            });
        });
    };

    initSubMenuDropdowns();
})();
