(() => {
    const $iframeParentLinks = document.querySelectorAll('.iframe-parent-link');

    if (!$iframeParentLinks) return;

    $iframeParentLinks.forEach($iframeParentLink => {
        $iframeParentLink.addEventListener('click', e => {
            if (!window.top) return;
            
            e.preventDefault();

            window.top.location.href = $iframeParentLink.href;
        });
    })
})();
