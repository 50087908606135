(() => {
    const $resizerBlocks = document.querySelectorAll('[data-min-width], [data-max-width]');

    const initResizerBlocks = () => {
        if (!$resizerBlocks) return;
    
        $resizerBlocks.forEach($resizerBlock => {
            const minWidth = $resizerBlock?.dataset?.minWidth ? parseInt($resizerBlock.dataset.minWidth) : null;
            const maxWidth = $resizerBlock?.dataset?.maxWidth ? parseInt($resizerBlock.dataset.maxWidth) : null;

            if ((!minWidth || window.innerWidth >= minWidth) &&
                (!maxWidth || window.innerWidth <= maxWidth)) {

                $resizerBlock.classList.remove('block--skip');
                $resizerBlock.classList.remove('block--hidden');
            } else {
                $resizerBlock.classList.add('block--skip');
                $resizerBlock.classList.add('block--hidden');
            }
        });
    };

    window.addEventListener('resize', initResizerBlocks);
    window.addEventListener('load', initResizerBlocks);

    initResizerBlocks();
})();
